import React from 'react';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import FeaturesList from '../components/FeaturesList';
import PlanCard from '../components/PlanCard';
import PortfolioSection from '../components/PortfolioSection';
import VideoSection from '../components/VideoSection';
import PasoSolicitudProducto from '../components/PasoSolicitudProducto';
import ScreenshotsSection from '../components/ScreenshotsSection';
import Contacto from '../components/Contacto';

const HomePage = () => {
  return (
    <div>
      <NavBar />
      <Hero />
      <div className="mx-auto">
        <section id="features" className="border-b-2"><FeaturesList /></section>
        <section id="steps" className="border-b-2"><PasoSolicitudProducto /></section>
        <section id="plans" className="border-b-2"><PlanCard /></section>
        <section id="screenshots" className="border-b-2"><ScreenshotsSection /></section>
        <section id="videos" className="border-b-2"><VideoSection /></section>
        <section id="portfolio" className="border-b-2"><PortfolioSection /></section>
        <section id="contact" className="border-b-2"><Contacto /></section>
      </div>
    </div>
  );
}

export default HomePage;
