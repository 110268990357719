import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-gray-800 text-white shadow-lg">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="text-lg font-bold">Centrocomercial.com.co</div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                        >
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'} />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                            <NavLink to="/" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Inicio</NavLink>
                            <Link to="features" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Funcionalidades</Link>
                            <Link to="steps" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Cómo Solicitar</Link>
                            <Link to="plans" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Planes</Link>
                            <Link to="screenshots" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Capturas</Link>
                            <Link to="videos" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Videos</Link>
                            <Link to="portfolio" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Portafolio</Link>
                            <Link to="contact" smooth={true} duration={500} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Contacto</Link>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <NavLink to="/" className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Inicio</NavLink>
                        <Link to="features" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Funcionalidades</Link>
                        <Link to="steps" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Cómo Solicitar</Link>
                        <Link to="plans" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Planes</Link>
                        <Link to="screenshots" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Capturas</Link>
                        <Link to="videos" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Videos</Link>
                        <Link to="portfolio" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Portafolio</Link>
                        <Link to="contact" smooth={true} duration={500} className="hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">Contacto</Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
