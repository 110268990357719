import React from 'react';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <div
      className="hero relative bg-cover bg-center h-screen text-white flex flex-col justify-center items-center"
      style={{ backgroundImage: `url('/bg.jpg')` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Fondo oscuro con opacidad */}
      <div className="relative z-10 max-w-3xl text-center">
        <h1 className="text-6xl font-bold mb-4">¡Comienza tu viaje digital hoy mismo!</h1>
        <p className="text-xl mb-8">En un mundo cada vez más digitalizado, la presencia en línea se ha convertido en un factor crucial para el éxito empresarial.</p>
        <Link
          to="contact"
          smooth={true}
          duration={500}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 cursor-pointer"
        >
          Contáctanos
        </Link>
      </div>
    </div>
  );
}

export default Hero;
