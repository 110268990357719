import React, { useState } from 'react';

const Contacto = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = '573182333790'; // Número de WhatsApp
    const whatsappMessage = `Nombre: ${name}%0AEmail: ${email}%0AMensaje: ${message}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${whatsappMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div
      className="contacto-page p-8 min-h-screen flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url('/bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-white bg-opacity-80 shadow-md rounded-lg p-6 w-full max-w-lg">
        <h1 className="text-4xl font-extrabold mb-8 text-center">Contáctanos</h1>
        <div className="mb-4">
          <h2 className="text-2xl font-semibold">Información de Contacto</h2>
          <p className="mt-2">Teléfono: <a href="tel:573182333790" className="text-blue-500">573182333790</a></p>
          <p className="mt-1">Email: <a href="mailto:contacto@cencomer.com" className="text-blue-500">contacto@cencomer.com</a></p>
          <p className="mt-1">Dirección: Calle 49 50-21 Local 9939 AA201950, Medellín, Antioquia, Colombia</p>
        </div>
        <form onSubmit={handleSubmit} className="mt-6">
          <label className="block mb-4">
            <span className="text-gray-700">Nombre:</span>
            <input
              type="text"
              className="mt-1 p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">Email:</span>
            <input
              type="email"
              className="mt-1 p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">Mensaje:</span>
            <textarea
              className="mt-1 p-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </label>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300"
          >
            Enviar a WhatsApp
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contacto;
