import React from 'react';

const PlanCard = ({ title, price, activation, features, button }) => {
    // Convertir el precio a formato con separador de miles
    const formattedPrice = parseInt(price).toLocaleString('es-CO');
    const formattedActivation = activation.replace(/\d+/, match => parseInt(match).toLocaleString('es-CO'));

    // Función para manejar el clic del botón
    const handleWhatsAppContact = () => {
        // Construir el mensaje con la información del plan
        const message = `Hola, estoy interesado en el plan ${title} por un precio de $${formattedPrice} COP. ${activation}. Características: ${features.join(', ')}. ¿Podrías proporcionarme más información para adquirir este plan?`;
        
        // Número de WhatsApp al que se enviará el mensaje
        const whatsappNumber = '573182333790';

        // Construir la URL de WhatsApp con el mensaje codificado
        const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

        // Abrir la URL en una nueva pestaña
        window.open(url, '_blank');
    };

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mx-auto max-w-full transform transition duration-300 hover:scale-105 mb-4">
            <div className="px-6 py-8">
                <div className='text-center'>
                    <h2 className="text-xl font-medium mb-2">{title}</h2>
                    <div className="text-4xl font-extrabold text-gray-600 mb-4">
                        <span className='font-bold mr-2'>$</span>{formattedPrice}<span className='ml-2 font-medium text-base'>COP</span>
                    </div>
                    <p className='text-md font-normal pb-2 mb-6 border-b border-gray-200'>{formattedActivation}</p>
                </div>
                <ul className="mb-6">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center text-gray-600 mb-2">
                            <svg className="w-5 h-5 mr-2 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M2.5 10c0-3.866 3.134-7 7-7s7 3.134 7 7-3.134 7-7 7-7-3.134-7-7zm11.14-3.17a5.002 5.002 0 00-8.74 4.64L5.29 9.7a6.004 6.004 0 0110.42-3.48l-1.07 1.08zm-8.74 7.34a5.002 5.002 0 008.74-4.64l1.61-1.61a6.004 6.004 0 01-10.42 3.48l1.07-1.07z" />
                            </svg>
                            {feature}
                        </li>
                    ))}
                </ul>
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300" onClick={handleWhatsAppContact}>
                    {button}
                </button>
            </div>
        </div>
    );
};

const Pricing = () => {
    const plans = [
        {
            title: "Mensual",
            price: "100000",
            activation: "Activación e Instalación $50.000",
            features: [
                "Marketplace 50 Productos",
                "Galería de Imágenes 30 imágenes",
                "Galería de Videos Youtube",
                "Web App instalable"
            ],
            button: "Seleccionar Plan"
        },
        {
            title: "Semestral",
            price: "450000",
            activation: "Activación e Instalación $50.000",
            features: [
                "Marketplace 50 Productos",
                "Galería de Imágenes 30 imágenes",
                "Galería de Videos Youtube",
                "Web App instalable",
                "Dominio .com incluido"
            ],
            button: "Seleccionar Plan"
        },
        {
            title: "Anual",
            price: "800000",
            activation: "Activación e Instalación $25.000",
            features: [
                "Marketplace 50 Productos",
                "Galería de Imágenes 30 imágenes",
                "Galería de Videos Youtube",
                "Web App instalable",
                "Dominio .com incluido",
                "Chat Bot 30 días Free"
            ],
            button: "Seleccionar Plan"
        }
    ];

    return (
        <div className="mx-auto py-8 md:py-12">
            <h1 className="text-3xl font-extrabold mb-8 text-center">
                ¡Descubre Nuestros Planes de Valor!
                <br />
                <span className='mt-2 font-light text-gray-600'>¡Elige la Opción Perfecta para Impulsar Tu Éxito Online!</span>
            </h1>
            <div className='container mx-auto'>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {plans.map((plan, index) => (
                        <div key={index} className='mx-4'>
                            <PlanCard {...plan} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;
