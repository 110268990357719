// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import FeaturesList from './components/FeaturesList';
import Footer from './components/Footer';
import PlanCard from './components/PlanCard';
import PortfolioSection from './components/PortfolioSection';
import VideoSection from './components/VideoSection';
import NavBar from './components/NavBar';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/funcionalidades" element={<FeaturesList />} />
          <Route path="/planes" element={<PlanCard />} />
          <Route path="/portafolio" element={<PortfolioSection />} />
          <Route path="/videos" element={<VideoSection />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
