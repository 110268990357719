import React from 'react';

// Componente reutilizable para representar un paso del proceso de solicitud
const Paso = ({ numero, descripcion }) => {
  return (
    <div className="w-full md:w-1/3 p-4 text-center transform hover:scale-110 transition duration-300">
      <div className="rounded-lg bg-gray-50 shadow-md mb-0 p-4">
        <div className="rounded-full bg-gray-950 w-12 h-12 flex items-center justify-center mx-auto mb-4 text-gray-300 font-bold text-4xl shadow-md">
          {numero}
        </div>
        <p className="text-gray-800 font-medium">{descripcion}</p>
      </div>
    </div>
  );
};

// Componente principal que muestra los pasos del proceso de solicitud
const PasoSolicitudProducto = () => {
  const pasos = [
    { numero: 1, descripcion: 'Envia la informacion de tu empresa o negocio' },
    { numero: 2, descripcion: 'Selecciona uno de los planes' },
    { numero: 3, descripcion: 'Realiza el pago de la activación del servicio' },
    { numero: 4, descripcion: 'Ingresa al panel administrativo' },
    { numero: 5, descripcion: 'Activación del dominio y publicación del sitio' },
    { numero: 6, descripcion: 'Realiza el pago del plan y listo' },
  ];

  return (
    <div className="w-full mx-auto my-12">
      <h1 className="text-3xl font-extrabold mb-8 text-center">
                ¡Tu pagina web en 4 horas o menos!
                <br />
                <span className='mt-2 font-medium'>Nuestro Portafolio en Acción</span>
            </h1>
      <div className='max-w-7xl mx-auto'>
      <div className="flex flex-wrap">
        {pasos.map((paso, index) => (
          <Paso key={index} numero={paso.numero} descripcion={paso.descripcion} />
        ))}
      </div>

      </div>
    </div>
  );
};

export default PasoSolicitudProducto;
