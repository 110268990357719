// src/components/Footer.jsx
import React from 'react';
// import DomainQRCode from '../DomainQRCode'; 

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <p>© 2024 By Cencomer.com Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
