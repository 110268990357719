import React from 'react';

const FeatureItem = ({ title, description }) => {
  return (
    <div className="border rounded-lg overflow-hidden shadow-md">
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-700 mb-6">{description}</p>
      </div>
    </div>
  );
};

const FeaturesList = () => {
  const features = [
    {
      title: "Publicación de Información de Contacto",
      description: "Olvídate de las complicaciones. Con nuestra plataforma, puedes publicar y actualizar tu información de contacto de manera rápida y sencilla, asegurando que tus clientes siempre puedan encontrarte."
    },
    {
      title: "Productos Marketplace",
      description: "¿Quieres ampliar tu alcance y aumentar tus ventas en línea? Nuestro Marketplace te ofrece la oportunidad perfecta para listar y vender hasta 50 productos, todo en un solo lugar."
    },
    {
      title: "Galería de Imágenes y Videos",
      description: "¿Quieres mostrar tus productos o servicios de manera más impactante? Con nuestra galería de imágenes y videos, puedes presentar tus productos de una manera dinámica y atractiva, cautivando a tus clientes desde el primer vistazo."
    },
    {
      title: "Accesos a Redes Sociales",
      description: "La integración con las redes sociales es fundamental en el mundo actual. Con nuestra plataforma, puedes compartir fácilmente tu contenido en todas las plataformas principales, manteniendo a tu audiencia comprometida y conectada."
    },
  ];

  const handleWhatsAppContact = () => {
    const whatsappNumber = '573182333790'; // Cambiar al número de WhatsApp al que deseas enviar el mensaje
    const message = 'Hola, me gustaría obtener más información sobre cómo adquirir uno de los planes. ¿Podrían ayudarme?'; // Mensaje predefinido
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="bg-gray-100 py-12 px-6 md:px-12 lg:px-24">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">Funcionalidades Destacadas</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
        {/* Opciones de Pago */}
        <div className="mt-12 border rounded-lg overflow-hidden shadow-md">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-4">Opciones de Pago</h3>
            <p className="text-gray-700 mb-6">En Cencomer, entendemos la importancia de ofrecer opciones de pago flexibles y convenientes para tus clientes. Es por eso que brindamos la posibilidad de publicar enlaces de pago y ofrecemos una variedad de métodos de pago disponibles, desde transferencias electrónicas hasta billeteras digitales.</p>
          </div>
        </div>
        {/* Call to Action */}
        <div className="mt-12 text-center">
          <p className="text-lg text-gray-700 mb-6">Imagina tener todas estas herramientas a tu disposición, listas para llevar tu presencia en línea al siguiente nivel. ¡Es el momento de unirte a Cencomer y comenzar tu viaje digital hoy mismo! Contáctanos ahora para descubrir cómo podemos ayudarte a impulsar tus ventas y hacer crecer tu negocio en línea.</p>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300" onClick={handleWhatsAppContact}>¡Contáctanos!</button>
        </div>
      </div>
    </div>
  );
};

export default FeaturesList;
