import React, { useState, useEffect } from 'react';

const ScreenshotsSection = () => {
    const [screenshots, setScreenshots] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        fetch('/capturas-pantallas/capturas.json')
            .then(response => response.json())
            .then(data => setScreenshots(data))
            .catch(error => console.error('Error fetching screenshots data:', error));
    }, []);

    const openModal = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const prevImage = () => {
        setCurrentIndex((currentIndex - 1 + screenshots.length) % screenshots.length);
    };

    const nextImage = () => {
        setCurrentIndex((currentIndex + 1) % screenshots.length);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!isOpen) return;
            if (event.key === 'ArrowLeft') {
                prevImage();
            } else if (event.key === 'ArrowRight') {
                nextImage();
            } else if (event.key === 'Escape') {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen, currentIndex]);

    return (
        <div className="mx-auto py-8 bg-gray-200">
            <div className='lg:max-w-7xl mx-auto p-8'>
                <h1 className="text-3xl font-extrabold mb-8 text-center">
                    Capturas de Pantalla
                    <br />
                    <span className='mt-2 font-medium'>Visualiza nuestro aplicativo en acción</span>
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {screenshots.map((screenshot, index) => (
                        <div key={index} className="bg-gray-50 shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105" onClick={() => openModal(index)}>
                            <img src={`/capturas-pantallas/${screenshot.new_name}`} alt={`Screenshot ${index + 1}`} className="w-full h-48 object-cover" />
                        </div>
                    ))}
                </div>

                {isOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                        <button onClick={closeModal} className="absolute top-4 right-4 text-white text-3xl">&times;</button>
                        <button onClick={prevImage} className="absolute left-4 text-white text-3xl">&lsaquo;</button>
                        <button onClick={nextImage} className="absolute right-4 text-white text-3xl">&rsaquo;</button>
                        <div className="relative max-w-lg lg:max-w-7xl max-h-full">
                            <img src={`/capturas-pantallas/${screenshots[currentIndex].new_name}`} alt={`Screenshot ${currentIndex + 1}`} className="w-full h-auto object-contain" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScreenshotsSection;
