import React from 'react';

const VideoCard = ({ src, title }) => {
  return (
    <div className="relative overflow-hidden rounded-lg shadow-lg">
      <iframe
        className="w-full h-96"
        src={src}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const VideoSection = () => {
  return (
    <div className="bg-gray-900 py-12">
      <div className="container mx-auto">
        <h2 className="text-3xl font-extrabold text-white text-center mb-8 mx-auto">Explora Nuestros Videos</h2>
        <div className="grid grid-cols-1 md:w-1/2 mx-auto">
          <VideoCard src="https://www.youtube.com/embed/CDFgcXtrwnc" title="Video 1" />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
