import React, { useState, useEffect } from 'react';

const PortfolioSection = () => {
    const [portfolioItems, setPortfolioItems] = useState([]);

    useEffect(() => {
        fetch('/portfolio.json')
            .then(response => response.json())
            .then(data => setPortfolioItems(data))
            .catch(error => console.error('Error fetching portfolio data:', error));
    }, []);

    return (
        <div className="container mx-auto py-8 bg-white">
            <h1 className="text-3xl font-extrabold mb-8 text-center">
                ¡Inspiración Digital!
                <br />
                <span className='mt-2 font-medium'>Nuestro Portafolio en Acción</span>
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {portfolioItems.map((item, index) => (
                    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-600">
                        <div className="bg-gray-50 shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105">
                            {item.img ? (
                                <img src={`/images-portafolio/${item.img}`} alt={item.title} className="w-full h-48 object-cover" onError={(e) => { e.target.onerror = null; e.target.src = ''; }} />
                            ) : (
                                <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                                    <span className="text-xl font-semibold">{item.title}</span>
                                </div>
                            )}
                            <div className="py-4 px-8">
                                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default PortfolioSection;
